<script>
	import Navbar from "./components/Navbar.svelte";
	import Footer from "./components/Footer.svelte";
	import Header from "./components/Header.svelte";
	import About from "./components/About.svelte";
	import Projects from "./components/Projects.svelte";
	import Contact from "./components/Contact.svelte";

	let title = "edsonDev"
	/**
	 * -------------------- Navigation Bar --------------------
	 */
	let brand = "edsonDev";
	let nav_items = [
		["Home", "#"],
		["About", "#about"],
		["Projects", "#projects"],
		["Contact", "#contact"],
	];
	let github = "#";

	/**
	 * -------------------- Header Section --------------------
	 */
	let header_title_1 = "Hello,";
	let header_title_2 = "I'm Dave.";
	let header_title_3 = "Developer. Engineer.";

	/**
	 * -------------------- About Section --------------------
	 */
	let bio_title = "About Me";
	let skills_title = "My Skills";
	let skills = {
		Languages: [
			[".Net Core", "fas fa-code", 90],
			["Powershell", "fab fa-windows", 90],
			["HTML/CSS", "fab fa-html5", 65],
			["JavaScript", "fab fa-js-square", 70],
			["Svelte", "fas fa-code", 65],
		],
		Frameworks: [
			["Full Stack Web Development", "", 75],
			["MS SharePoint", "", 90],
			["MS Power Platform", "", 90],
			["Azure Development", "", 65],
		],
	};
	let bio =
		"I am a garage developer with an expert tetris skill for fitting as many assymmetrical items as possible inside of 200 sq. ft.,  I am also great at doing whatever dirty IT work you want.  Currently, people like to throw SharePoint projects at me.";

	/**
	 * -------------------- Project Section --------------------
	 */
	let projects_title = "Project Showcase";
	let projects = [
		[
			"SharePoint GCC Migration",
			"Migrated an entire SharePoint Online environment to Microsoft's GCC.",
			"#",
		],
		[
			"SharePoint Growth and Sales Area",
			"Architected a set of SharePoint sites for efficiently organizing Growth and Sales. Built a Proposals area to track, collaborate, and manage opportunities and proposals automatically with PowerApps.",
			"#",
		],
		[
			"Big Whoop",
			"A project that will never be complete.",
			"#",
		],
		[
			"Developer Consulting",
			"Helping to mature IT systems by providing thought leadership, expert advice, and hands-on support when needed.",
			"#",
		],
	];
	/**
	 * -------------------- Contact Section --------------------
	 */
	let contact_title = "Contact Me";
	let social = [
		["LinkedIn", "fab fa-linkedin", "https://www.linkedin.com/in/david-edson-406114150/"],
		["Email", "fas fa-envelope", "mailto:david.edson@edsondev.com"]
	];

	/**
	 * -------------------- Theme Toggle --------------------
	 */
	let state = "dark"; // Change this to "light" to use light theme.

	let text_color = "dark";
	let bg_color = "light";

	if (state === "dark") {
		text_color = "light";
		bg_color = "dark";
	}
</script>
<svelte:head>
	<title>{title}</title>
	
</svelte:head>
<main class="bg-{bg_color} text-{text_color} min-vh-100">
	<div class="bg-pattern">
		<Navbar Id="navbar" {brand} {nav_items} {github} {state} />
		<Header
			Id="#"
			{header_title_1}
			{header_title_2}
			{header_title_3}
			{state}
		/>
		<About Id="about" {bio_title} {bio} {skills_title} {skills} {state} />
		<Projects Id="projects" {projects_title} {projects} {state} />
		<Contact Id="contact" {contact_title} {social} {state} />
		<Footer Id="footer" {brand} {state} />
	</div>
</main>

<style>
</style>
